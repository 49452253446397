<template>
  <div class="classDetails">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/class/classList' }">我的班级</el-breadcrumb-item>
        <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="classForm" :rules="rules" ref="rulForm" class="demo-ruleForm">
        <div class="form-top">
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="班级名称" prop="class_name">
                <el-input class="form-input" size="small" v-model="classForm.class_name"></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="学生人数" prop="count">
                <el-input class="form-input" size="small" v-model="classForm.count"></el-input>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="班级介绍">
            <el-input type="textarea" class="form-input" v-model="classForm.class_cover"></el-input>
          </el-form-item>
        </div>
        <div class="form-bottom">
          <el-form-item label="选择课程" prop="lesson_id">
            <el-select class="form-input" size="small" @change="getLessonVideo" v-model="classForm.lesson_id" placeholder="请选择课程">
              <el-option v-for="item in lessonData" :key="item.shop_lesson_id" :label="item.lesson_name" :value="item.shop_lesson_id"></el-option>
            </el-select>
            <el-transfer v-model="classForm.invert_lesson" :data="videoData" :titles="['未发布课程', '已发布课程']"></el-transfer>
          </el-form-item>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getLessonList, getVideo, getClassDetails, shopAddClass } from '../../../request/api'

export default {
  data () {
    return {
      breadcrumb: '新增班级',
      classForm: {
        shop_class_id: '',
        class_name: '',
        class_cover: '',
        count: '',
        invert_lesson: [],
        lesson_id: ''
      },
      rules: {
        class_name: [
          { required: true, message: '请输入班级名称', trigger: 'blur' }
        ],
        count: [
          { required: true, message: '请输入学生人数', trigger: 'blur' }
        ]
      },
      lessonData: [],
      videoData: []
    }
  },
  methods: {
    // 确认提交按钮
    async submitForm () {
      this.$refs.rulForm.validate(valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
      })
      if (this.classForm.class_name === '' || this.classForm.count === '') {
        this.$message.warning('请完善字段信息')
        return
      }
      this.classForm.videoData = JSON.stringify(this.videoData)

      const res = await shopAddClass(this.classForm)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('添加成功')
      this.$router.push({ path: '/class/classList' })
    },
    // 返回
    resetForm () {
      this.$router.back()
    },
    // 获取课程小列表
    async getLesson () {
      const res = await getLessonList()
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.lessonData = res.data.data
    },
    // 选中值时触发
    async getLessonVideo (lesson_id) {
      const res = await getVideo(lesson_id)
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.videoData = res.data.data.map(element => {
        return {
          'key': element.shop_lesson_video_id,
          'label': element.video_name
        }
      })
    },
    // 初始化操作
    async init () {
      if (this.classForm.shop_class_id !== undefined) {
        this.breadcrumb = '编辑班级'
        const res = await getClassDetails(this.classForm.shop_class_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.classForm = (res.data.data)
        this.getLessonVideo(this.classForm.lesson_id)
      }
    }
  },
  created () {
    this.classForm.shop_class_id = this.$route.query.shop_class_id
  },
  mounted () {
    this.getLesson()
    this.init()
  }
}
</script>

<style lang="less" scoped>
.classDetails {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo {
          display: flex;
          .form-compo-div {
            width: 300px;
            .form-input {
              width: 204px;
            }
          }
        }
        .form-input {
          width: 514px;
        }
      }
      .form-bottom {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
        .form-input {
          width: 514px;
          margin-bottom: 18px;
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }
}
</style>